<template>
  <b-modal
    v-model="onControl"
    title="Send Statement"
    title-tag="h3"
    size="lg"
    header-class="p-0"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    no-close-on-backdrop
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Send Statement
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-row>
        <b-col
          lg="6"
          class="mt-1"
        >
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client | {{ client.client_name }}
            </p>
          </div>
        </b-col>
        <b-col
          lg="6"
          class="mt-1"
        >
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Account | {{ client.account }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <span class="d-block text-primary font-medium-1">STEP 1</span>
          <validation-provider
            v-slot="{ errors }"
            name="type_send"
            rules="required"
          >
            <b-form-group label="Select type send:">
              <div class="d-flex">
                <b-form-radio
                  v-model="typeSend"
                  value="1"
                >@ EMAIL
                </b-form-radio>
                <b-form-radio
                  v-model="typeSend"
                  value="2"
                  class="ml-2"
                ><feather-icon icon="MailIcon" /> CORRESPONDENCE
                </b-form-radio>
              </div>
              <span
                v-if="errors[0]"
                class="text-danger"
              >
                Type Send {{ errors[0] }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <span class="d-block text-primary font-medium-1">STEP 2</span>
          <b-button
            style="margin-top: 0.5rem"
            variant="success"
            :disabled="typeSend == null"
            @click="generateStatement"
          >
            <b-spinner
              v-if="generatingPdf"
              small
              type="grow"
            />
            GENERATE STATEMENT
          </b-button>
          <a
            v-if="url_pdf !== ''"
            class="ml-1"
            target="_blank"
            :href="url_pdf"
          >
            <amg-icon
              size="20"
              class="text-danger"
              icon="FilePdfIcon"
            />
            <span style="margin-left: 0.3rem">{{ pdfName }}</span>
          </a>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <span class="d-block text-primary font-medium-1">STEP 3</span>
          <b-form-group label="Observation">
            <validation-provider
              v-slot="{ errors }"
              name="observation"
              rules="required"
            >
              <b-form-textarea v-model="observation" />
              <span
                v-if="errors[0]"
                class="text-danger"
              >
                Observation {{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-form-checkbox
        v-model="saveAsNote"
        checked="1"
        value="1"
        unchecked-value="0"
      >
        Save as Note
      </b-form-checkbox>
      <b-button
        :disabled="disableSendButton"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        @click="save"
      >
        <feather-icon icon="SendIcon" />
        SEND
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientsDashboardServices from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services'

export default {
  data() {
    return {
      onControl: false,
      saveAsNote: 0,
      typeSend: null,
      observation: null,
      disableSendButton: true,
      generatePdfData: {},
      pdfName: '',
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      url_pdf: '',
      generatingPdf: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      client: 'DebtSolutionClients/G_CLIENTS',
    }),
  },
  created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async save() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const data = await ClientsDashboardServices.sendEmailStatus({
              statement: this.typeSend,
              observation: this.observation,
              note: this.saveAsNote,
              iduser: this.currentUser.user_id,
              accountid: this.$route.params.idClient,
              urlpdf: this.generatePdfData[1],
              statuspdf: this.typeSend === 1 ? 3 : 1,
              urlfull: this.url_pdf,
              n_type: 3,
            })
            if (data.status === 200) {
              this.showSuccessSwal()
              this.close()
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async generateStatement() {
      try {
        this.url_pdf = ''
        this.pdfName = ''
        this.generatingPdf = true
        const data = await ClientsDashboardServices.savePdfStatement({
          accountid: this.$route.params.idClient,
          typepdf: Number(this.typeSend),
          leadid: this.client.lead_id,
        })
        if (data.status === 200) {
          this.generatePdfData = data.data
          this.disableSendButton = false
          this.url_pdf = this.generatePdfData[0]
          this.pdfName = 'OPEN_STATEMENT'
          this.generatingPdf = false
        }
      } catch (e) {
        console.log(e)
        this.generatingPdf = false
      }
    },
  },
}
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
.title_lable{
  width: 150px;
}
</style>
