<template>
  <div>
    <lead-list-add-new
      :key="keyCreateList"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @saveLead="keyCreateList = Math.random()"
    />
    <header-slot :clients-search="true">
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action class="p-0" />

          <b-button
            class="mr-1"
            variant="custom-amg"
            :disabled="isResponsibleAccount"
            @click="isAddNewUserSidebarActive = true"
          >
            CREATE LEAD</b-button
          >

          <b-button-group>
            <b-button
              :disabled="isResponsibleAccount"
              size="sm"
              variant="custom-amg"
              @click="openModalApp"
            >
              <div class="d-flex justify-content-center align-items-center">
                <amg-icon icon="CustomAppIcon" class="mr-1" size="20" />
                <span class="font-small-5">App</span>
              </div>
            </b-button>
            <b-button
              v-b-tooltip.hover.top="`History Password`"
              variant="custom-amg"
              :disabled="isResponsibleAccount"
              @click="openTrackingChangePassword"
            >
              <feather-icon icon="MenuIcon" />
            </b-button>
          </b-button-group>

          <!-- <b-dropdown
            :disabled="isResponsibleAccount"
            class="ml-1"
            variant="custom-amg"
            right
            text="ADD SERVICE"
          >
            <template #button-content>
              <div class="d-flex gap-50">
                <feather-icon
                  icon="PlusCircleIcon"
                  size="16"
                  class="text-white !important"
                />
                <p class="m-0 text-white">Services</p>
              </div>
            </template>
            <b-dropdown-item @click="openDerivationActionModal()">
              <feather-icon icon="CornerUpLeftIcon" /><span
                style="margin-left: 0.5rem"
                >Send to Sales</span
              >
            </b-dropdown-item>
          </b-dropdown> -->

          <!-- Share -->
          <b-button
            v-if="[1, 2, 17].includes(currentUser.role_id)"
            class="ml-1"
            variant="custom-amg"
            :disabled="isResponsibleAccount"
            @click="openModalShare"
          >
            <feather-icon icon="Share2Icon" />
            Share
          </b-button>
          <!-- Other Programs -->
          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1"
            variant="custom-amg"
            @click="openModalProgramList"
          >
            <tabler-icon icon="DotsVerticalIcon" />
            Others Programs
          </b-button>
          <!--        New Charge-->
          <b-button
            class="ml-1"
            variant="custom-amg"
            :disabled="isResponsibleAccount"
            @click="openChargeModal(null)"
          >
            <feather-icon icon="PlusCircleIcon" size="16" />
            Charge
          </b-button>
          <!-- Send CR -->
          <b-dropdown
            :disabled="isResponsibleAccount"
            class="ml-1"
            variant="custom-amg"
            right
            text="Request"
          >
            <template #button-content>
              <div class="d-flex align-items-center gap-50">
                <feather-icon icon="FileTextIcon" class="text-white" />
                <p class="m-0 text-white">Request CR</p>
                <b-badge
                  v-if="countNcrForUpdate > 0"
                  v-b-tooltip.hover
                  title="The specialist requests a new NCR because it has already expired"
                  variant="info"
                  class="custom-badge-number"
                  >{{ countNcrForUpdate }}</b-badge
                >
                <b-badge
                  v-if="cPendingNcrByClient > 0"
                  v-b-tooltip.hover
                  title="The client has pending requests"
                  variant="warning"
                  class="custom-badge-number"
                  >{{ cPendingNcrByClient }}</b-badge
                >
              </div>
            </template>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              Automatic
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              Manual
            </b-dropdown-item>
            <b-dropdown-item @click="showCreditReport = true">
              History
              <b-badge v-if="cPendingNcrByClient > 0" variant="warning">{{
                cPendingNcrByClient
              }}</b-badge>
            </b-dropdown-item>
          </b-dropdown>
          <!-- New items -->
          <b-button
            class="ml-1 custom-button"
            @click="openModalCreateNewItems()"
          >
            <div class="d-flex">
              <div>New Items</div>
              <div>
                <b-badge
                  v-if="G_COUNTER_NEW_ITEMS > 0"
                  pill
                  variant="danger"
                  class="ml-1"
                >
                  {{ G_COUNTER_NEW_ITEMS }}
                </b-badge>
              </div>
            </div>
          </b-button>
          <!-- Send -->
          <b-dropdown
            :disabled="isResponsibleAccount"
            class="ml-1"
            right
            variant="custom-amg"
          >
            <template #button-content>
              <div class="d-flex gap-50">
                <feather-icon icon="SendIcon" class="text-white" />
                <p class="m-0 text-white">Send</p>
              </div>
            </template>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="openModalSendSms"> Send SMS</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistorySms"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="openModalSendEmail"> Send Email</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryEmail"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="modalLetterOn = true"> Send Letter</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryLetter"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="openModalStatement"> Statement</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryStatement"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </header-slot>
    <b-card no-body class="m-0">
      <div class="m-3">
        <b-row align-h="between">
          <b-col lg="3">
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>
            <b-form-group style="margin-bottom: 5px !important">
              <template v-if="validateAccount">
                <span class="font-weight-bold"
                  >START DATE : {{ client.start_date | myGlobal }}</span
                >
                <br />
                <span
                  style="font-weight: bolder; font-size: 17.5px"
                  @click="isResponsibleAccount ? '' : openEditLead()"
                  :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                  >{{ client.client_name }}
                </span>
                <span
                  v-if="
                    $store.getters[
                      'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                    ].is_responsible
                  "
                  class="text-primary"
                >
                  <tabler-icon
                    :icon="
                      $store.getters[
                        'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                      ].is_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                    style="margin-left: 5px"
                  />
                  <strong>CEO's client</strong>
                </span>
                <br />
              </template>
            </b-form-group>

            <b-form-group>
              <template v-if="validateAccount">
                <div style="font-size: 14.5px">
                  <div class="d-flex">
                    <span
                      style="
                        font-weight: bolder;
                        font-size: 14px;
                        margin-right: 5px;
                      "
                    >
                      {{ client.account }}
                    </span>
                    <badge-risky-clients
                      :id-account="client.id"
                      :risk-level="client.risk_level"
                      :risk-description="client.risk_description"
                      :risk-type="client.risk_type"
                    />
                  </div>
                  <StatusClientAccount />
                </div>
              </template>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <EfectivityCard v-if="showEfectivity" :client="client" />
          </b-col>
        </b-row>
      </div>
      <b-nav pills class="ml-3 mb-0 custom-tab-amg">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'personal-information-ce-customer-service-general' }
              : { name: 'personal-information-ce-customer-service' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Notes'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'task-notes-ce-customer-service-general' }
              : { name: 'task-notes-ce-customer-service' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Notes
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'pay-ce-customer-service-general' }
              : { name: 'pay-ce-customer-service' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Pay
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-files-ce-customer-service-general' }
              : { name: 'client-files-ce-customer-service' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          ><span style="margin-right: 18px">Files</span>
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 40px;
              margin-top: -2px;
            "
          >
            {{ countfileclient }}</span
          >
          <span
            v-if="countFilesAppClient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-info"
            style="
              font-size: 90% !important;
              margin-left: 85px;
              margin-top: -2px;
            "
          >
            {{ countFilesAppClient }}
          </span>
        </b-nav-item>
        <b-nav-item
          v-if="
            currentUser.role_id === 17 ||
            isCeo ||
            currentUser.role_id === 2 ||
            currentUser.role_id === 15
          "
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'acr-ce-customer-service-general' }
              : { name: 'acr-ce-customer-service' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >ACr
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Update'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'dis-ce-customer-service-general' }
              : { name: 'dis-ce-customer-service' }
          "
          :active="isActiveDispute"
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Credit
          <b-badge v-if="creditCounter > 0" pill variant="danger" class="ml-1">
            {{ creditCounter }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'call-log-client-ce-customer-service-general' }
              : { name: 'call-log-client-ce-customer-service' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          :active="isCallLog"
          >Call Log</b-nav-item
        >
        <b-nav-item
          v-if="validationArrRoles"
          v-b-tooltip.hover.bottomright="'Tickets'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-tickets-ce-customer-service-general' }
              : { name: 'client-tickets-ce-customer-service' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Customer Tickets
          <span
            v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="
                G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99
                  ? '99+'
                  : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open
              "
              badge-classes="badge-important"
            />
          </span>
        </b-nav-item>
      </b-nav>
    </b-card>
    <hr class="border-primary border-3 px-0 mt-0" />
    <router-view
      v-if="showViews"
      :has-access="hasAccessPermanent"
      :type-access="typeAccessPermanent"
    />

    <!-- Header Modals -->
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-share
      v-if="modalShare"
      :from-ce-digital="true"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <!-- Send CR -->
    <!-- <modal-create-ncr
      v-if="modalCreateNcr"
      :modal-create-ncr="modalCreateNcr"
      :typencr="1"
      @closeModalCreateNcr="closeModalCreateNcr"
    />

    <modal-create-ncr-realtor
      v-if="modalCreateNcrRealtor"
      :typencr="1"
      @closeMe="changeStatusModalCreateNcrRealtor(false)"
      @closeModalCreateNcrRealtor="closeModalCreateNcrRealtor"
    /> -->

    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncr-type="selectedNcr"
      @close="closeModalCreateNcr"
      @reload="refresh"
    />
    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-status-report
      v-if="modalStatusReport"
      :modal-status-report="modalStatusReport"
      @closeModalStatus="closeModalStatus"
    />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <send-to-call-rounds
      v-if="modalCallRound"
      :modal-call-round="modalCallRound"
      :name-leads="clientsSms"
      :type-call="0"
      @validateStatus="validateStatus"
      @closeModal="closeModalCallRound"
    />
    <add-charge-modal
      v-if="addChargeOn"
      :title-add-charge="'Add Charge'"
      :edit="null"
      @refresh="refresh"
      @close="closeChargeModal"
    />
    <app-modal
      v-if="modalApp"
      :name_client="client.client_name"
      :account_id="$route.params.idClient"
      @close="closeModalApp"
    />
    <notes-welcome-customer-service-dig-modal
      v-if="notesWelcomeCustomerServiceModalController"
      :id-work-plan="idWorkPlanForWelcome"
      :client="client"
      @close="closeNotesWelcomeCSD"
      @reload="getStatusWelOnChange"
    />
    <statement-modal v-if="modalStatementOpen" @close="closeModalStatement" />
    <send-letter-modal v-if="modalLetterOn" @close="modalLetterOn = false" />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />

    <tracking-app-password
      v-if="activeModalHistoryPassword"
      @close="closeModalTrackingAppPassword"
    />

    <letter-modal-tracking
      v-if="activeModalHistoryLetter"
      :active="activeModalHistoryLetter"
      @close="closeModalHistoryLetter"
    />
    <modal-history-statement
      v-if="activeModalHistoryStatement"
      :active="activeModalHistoryStatement"
      @close="closeModalHistoryStatement"
    />

    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      :n-client-account-id="$route.params.idClient"
      :account-client="client.account"
      :module="moduleId"
      @close="closeModalTrackingHistoryClaims"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <derivation-action-modal
      v-if="derivationActionModal"
      :derivation-data="derivationData"
      @closeModal="derivationActionModal = false"
    />
    <credit-report-modal
      v-if="showCreditReport"
      :lead="clientNcr"
      @closeModal="showCreditReport = false"
    />

    <new-items-modal
      v-if="showModalNewItems"
      :clientData="clientData"
      :editMode="true"
      @close="showModalNewItems = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import iconApple from "@/assets/images/icons/apple.png";
// Import Components
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ClientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import LeadListAddNew from "@/views/commons/components/lead-programs/components/sidebar/LeadListAddNew.vue";

// Import Modals

import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare.vue";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList.vue";
// import ModalCreateNcr from "@/views/commons/components/clients/dashboard/information-client/modals/ModalCreateNcr.vue"
// import ModalCreateNcrRealtor from "@/views/commons/components/ncr-realtor/views/components/modals/ModalCreateNcrRealtor.vue"
import ModalStatusReport from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import SendToCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds.vue";
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import AppModal from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/AppModal.vue";
import NotesWelcomeCustomerServiceDigModal from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/NotesWelcomeCustomerServiceDigModal.vue";
import StatementModal from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/StatementModal.vue";
import SendLetterModal from "@/views/boost-credit/views/clients/dashboard/information-client/modals/SendLetterModal.vue";
import ModalHistoryEmail from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryEmail.vue";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";
import TrackingAppPassword from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/TrackingAppPassword.vue";

import LetterModalTracking from "@/views/boost-credit/views/clients/dashboard/information-client/modals/LetterModalTracking.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";
import ModalTrackingHistoryClaims from "@/views/commons/components/claims_v2/components/Modals/ModalTrackingHistoryClaims.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import ConnectionClientDashboardService from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/service/dashborard.service";
import EfectivityClient from "@/views/commons/components/clients/components/efectivity/EfectivityClient.vue";
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
import EfectivityCard from "@/views/commons/components/ncr-efectivity/CardNcrEfectivity.vue";
import NewItemsModal from "@/views/commons/components/request-ncr/views/modals/NewItemsModal.vue";
import CreditReportModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/CreditReportModal.vue";
export default {
  components: {
    BadgeRiskyClients,
    TrackingAppPassword,
    ModalHistoryEmail,
    ModalHistorySms,
    SendToCallRounds,
    ModalShare,
    ModalProgramList,
    // ModalCreateNcr,
    // ModalCreateNcrRealtor,
    ModalSendSms,
    ModalSendEmail,
    ModalStatusReport,
    LeadUpdate,
    ModalHistory,
    AddChargeModal,
    AppModal,
    NotesWelcomeCustomerServiceDigModal,
    StatementModal,
    SendLetterModal,
    LetterModalTracking,
    ModalHistoryStatement,
    ModalTrackingHistoryClaims,
    ModalAddService,
    LeadListAddNew,
    DerivationActionModal,
    EfectivityClient,
    ModalCreateNcrRequest,
    CourtInfoAction,
    StatusClientAccount,
    EfectivityCard,
    NewItemsModal,
    CreditReportModal,
  },

  props: {
    hasAccess: {
      // true if it comes from an alert or from the agent dashboard, false as default if it comes from route
      type: Boolean,
      default: false,
    },
    typeAccess: {
      // type of access, welcome, letters, ncr, etc
      type: Number,
      default: null,
    },
    wayAccess: {
      // type of access, welcome, letters, ncr, etc
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      clientNcr: {},
      showCreditReport: false,
      showViews: false,
      totalClaims: 0,
      apiApp: process.env.VUE_APP_BASE_APP_URL,
      activeModalHistoryPassword: false,
      activeModalHistorySms: false,
      activeModalHistoryEmail: false,
      modalApp: false,
      iconApple,
      cont: 0,
      validateStatusCall: false,
      clientsSms: [],
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalStatusReport: false,
      modalCallRound: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      addChargeOn: false,
      notesWelcomeCustomerServiceModalController: false,
      idWorkPlanForWelcome: null,
      modalStatementOpen: false,
      modalLetterOn: false,
      activeModalHistoryLetter: false,
      activeModalHistoryStatement: false,
      modalHistoryClaims: false,
      modalOpenAddService: false,
      typeModal: null,
      event: null,
      detailsClient: {},
      hasAccessPermanent: false,
      typeAccessPermanent: false,
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      keyCreateList: 0,
      isAddNewUserSidebarActive: false,
      typeAddChange: null,
      derivationActionModal: false,
      derivationData: null,
      efectivityClient: {
        positive: 0,
        updated: 0,
        deleted: 0,
        total_accounts: 0,
      },
      selectedNcr: null,
      countNcrForUpdate: null,
      showModalNewItems: false,
      clientData: {},
    };
  },
  computed: {
    isPersonalInformation() {
      return this.$route.fullPath.includes("personal-information");
    },
    isService() {
      return this.$route.matched?.[2]?.name == "services-client-cedigital";
    },
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },

    validationArrRoles() {
      if (
        this.currentUser.arrRoles.find(
          (rol) =>
            (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
            rol.module_id !== 24 &&
            rol.user_id !== 38
        )
      ) {
        return true;
      }
      return false;
    },
    isActiveDispute() {
      if (this.$route.matched[0].meta.general) {
        return (
          this.$route.matched?.[1]?.name == "dis-ce-customer-service-general"
        );
      } else {
        return this.$route.matched?.[2]?.name == "dis-ce-customer-service";
      }
    },
    efectivity() {
      return this.client.efectivity_percentage ?? 0;
    },
    showEfectivity() {
      return this.client.id != undefined;
    },
    progressVariant() {
      if (this.efectivity >= 40) return "success";
      return "danger";
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    isSpecialRole() {
      return (
        this.isCeo ||
        this.isSupervisor ||
        this.isChief ||
        this.isAssistantSupervisor
      );
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      G_UPDATE_EVALUATED_BY_SP: "DebtSolutionClients/G_UPDATE_EVALUATED_BY_SP",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      countFilesAppClient: "DebtSolutionClients/G_COUNT_FILES_APP",
      G_OPEN_MODAL_ACTIONS: "CeDigitalWelcomeActions/G_OPEN_MODAL_ACTIONS",
      G_FINISHED_DASHBOARD_ACTION:
        "DebtSolutionClients/G_FINISHED_DASHBOARD_ACTION",
      G_TRACKING_ID: "DashboardCustomerServiceStore/G_TRACKING_ID",
      G_DbAgentStore: "DbAgentStore/G_STORE",
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      G_IN_COURT: "ParagonClientDashboard/G_IN_COURT",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_ANALYSIS_CR: "AnalysisCrStore/G_COUNT_ANALYSIS_CR",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
      G_COUNTER_NEW_ITEMS: "NewItemsStore/G_COUNTER_NEW_ITEMS",
      getEfectivity: "ReportStore/G_REFRESH_EFECTIVITY",
      missingFiles: "ReportStore/G_MISSING_FILES",
    }),
    creditCounter() {
      return (
        // this.G_COUNT_ANALYSIS_CR.toRemove +
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.missingFiles +
        this.updates +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ncrParams() {
      const {
        id,
        account,
        client_name: client,
        program_id,
        lead_id,
      } = this.client;
      const { user_id, role_id } = this.currentUser;
      return {
        client_account_id: id,
        id, // client_account_id
        account,
        program_id,
        lead_id,
        fullname: client, // full name
        user_id,
        role_id,
        type: "CLIENT",
        used_cards: JSON.stringify([]),
      };
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    this.addPreloader();
    const { data } = await ClientsOptionsServices.getMissingFiles({
      accountId: this.$route.params.idClient,
    });
    await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    await this.A_GET_ALL_SERVICES_COUNTER({
      client_account_id: this.$route.params.idClient,
      module_id: this.moduleId,
    });
    await this.A_COUNT_PENDING_WP_CLIENT({
      client_account_id: this.$route.params.idClient,
    });
    await this.getUpdates();
    await this.getCountAccountsToRemove();
    await this.$store.dispatch(
      "NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO",
      { account_id: this.$route.params.idClient }
    );
    // let nameRoute = this.$route.matched[0].name;
    // if (nameRoute != "ce-customer-service-dashboard-general") {
    //   if (!this.hasAccess && this.isAgent) {
    //     await this.$router.push({ name: "ce-dashboard-agent" });
    //   }
    //   if (!this.hasAccess && this.isTechnicalAssistant) {
    //     await this.$router.push("/cedigital");
    //   }
    //   // validar que type access sea welcome preguntar si es welcome
    //   if (this.isAgent) {
    //     this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    //     this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
    //       type: "hidden",
    //     });
    //   }
    //   if (this.$route.matched[0].meta.general) {
    //     this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    //     this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
    //       type: "sticky",
    //     });
    //   }
    //   this.hasAccessPermanent = this.hasAccess;
    //   this.typeAccessPermanent = this.typeAccess;
    //   if (this.isAgent && this.typeAccess === 1) {
    //     await this.insertMessageCE();
    //   }
    // }

    try {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_UPDATE_EVALUATED_BY_SP", {
          clientAccountId: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_CLAIMS", {
          client_account_id: this.$route.params.idClient,
        }),
        this.getFirstWorkPlan(),
      ]);
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
    // Revisar si viene contestando un callround y aplicar comision
    if (this.G_DbAgentStore.answeringCR) {
      const params = {
        commision_type: 2,
        agent_id: this.currentUser.user_id,
        client_account: this.$route.params.idClient,
        module_id: this.moduleId,
      };
      try {
        await FinancialCommissionsService.createCommissionCed(params);
        this.endCallround();
      } catch (error) {
        console.error(error);
        this.endCallround();
      }
    }
    await Promise.all([
      this.getLeadEdit(),
      this.getStateLeads(),
      this.getStatusLeads(),
      this.getSourceLeads(),
      this.getOwners(),
      this.getPrograms(),
      this.getSourceNames(),
      this.getStates(),
      this.getEeuuStates(),
      this.getCountries(),
      this.getSellers(),
      this.getCounterClaims(),
      this.countRequestNcrForUpdate(),
      this.getEfectivityClient(),
    ]);
    this.showViews = true;

    // get client to search ncrs
    await this.getLead(this.client.lead_id);
    // set client NCR
    this.clientNcr = {
      client_account_id: this.client.id,
      account: this.client.account,
      client_id: this.client.client_id,
      lead_id: this.client.lead_id,
      ...this.S_LEAD,
    };
    await this.A_GET_NEW_ITEMS_COUNTER({
      client_account_id: this.$route.params.idClient,
    });
    this.removePreloader();
  },
  async mounted() {
    // await this.A_COUNT_ANALYSIS_CR({idClient: this.$route.params.idClient , typeView: 'REMOVE'});
    window.addEventListener("beforeunload", this.beforeWindowUnload);
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({
      user_id: this.currentUser.user_id,
      client_account_id: this.$route.params.idClient,
    });
    await this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient);
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.G_FINISHED_DASHBOARD_ACTION === null &&
      this.isAgent &&
      this.hasAccess
    ) {
      const answer = window.confirm(
        "Do you really want to leave? You still haven't finished the necessary actions!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.SET_CURRENT_ALERT_ID([{ alert_id: null }]);
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async destroyed() {
    if (this.isSpecialRole || this.isTechnicalAssistant) {
      await ClientsDashboardServices.updateTrackingDashboard({
        tracking_id: this.G_TRACKING_ID,
      });
    }
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");

    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");

    if (this.G_FINISHED_DASHBOARD_ACTION === null && this.isAgent) {
      await this.changeToSessionActive();
    }
  },
  methods: {
    // Derivation Client
    ...mapMutations({
      SET_CURRENT_ALERT_ID: "AutoAlertStore/SET_ID_ALERT_ACCEPTED",
    }),
    async getLead(lead_id) {
      try {
        await this.A_GET_LEAD({ id: lead_id });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openDerivationActionModal() {
      this.derivationData = {
        id: this.client.lead_id,
        client_id: this.client.client_id,
        client_account_id: this.client.id,
        catcher_by: this.currentUser.user_id,
        program_origin_id:
          (this.$route.matched[0].meta.module >= 21 &&
            this.$route.matched[0].meta.module <= 26) ||
          this.$route.matched[0].meta.module === 6
            ? 3
            : null,
        module_id: this.$route.matched[0].meta.module,
        notes: null,
        lead_name: this.client.client_name,
      };
      this.derivationActionModal = true;
    },
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? You still haven't finished the necessary actions!"
      );
    },

    confirmFinishedActionDashboard() {
      return (
        this.G_FINISHED_DASHBOARD_ACTION === null &&
        this.isAgent &&
        !this.confirmLeave()
      );
    },

    async beforeWindowUnload(e) {
      if (this.confirmFinishedActionDashboard()) {
        e.preventDefault();
        e.returnValue = "";
      } else {
        await ClientsDashboardServices.updateTrackingDashboard({
          tracking_id: this.G_TRACKING_ID,
        });
      }
    },
    async insertMessageCE() {
      try {
        const data = await ClientsOptionsServices.sendMessageWelcomeCDE({
          client_id: this.$route.params.idClient,
          user_id: this.currentUser.user_id,
        });
        if (data.status === 200) {
          const { status } = data.data;
          if (status === "S001") {
            this.showSuccessSwal("Message sent");
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async openTrackingChangePassword() {
      this.activeModalHistoryPassword = true;
    },
    closeModalTrackingAppPassword() {
      this.activeModalHistoryPassword = false;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.$route.params.idClient);
      this.activeModalHistoryEmail = true;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },
    openModalCreateNewItems() {
      this.clientData = {
        client_name: this.client.client_name,
        client_account: this.client.account,
        client_account_id: this.client.id,
        lead_id: this.client.lead_id,
      };
      this.showModalNewItems = true;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    openModalHistoryStatement() {
      this.activeModalHistoryStatement = true;
    },
    closeModalHistoryStatement() {
      this.activeModalHistoryStatement = false;
    },
    openModalApp() {
      this.modalApp = true;
    },
    closeModalApp() {
      this.modalApp = false;
    },
    openNotesWelcomeCSD() {
      this.notesWelcomeCustomerServiceModalController = true;
    },
    closeNotesWelcomeCSD() {
      this.notesWelcomeCustomerServiceModalController = false;
    },
    async getFirstWorkPlan() {
      try {
        const data = await ClientsOptionsServices.getFirstWorkPlan({
          idAccount: this.$route.params.idClient,
        });
        if (data.status === 200) {
          if (data.data.length > 0) {
            this.idWorkPlanForWelcome = data.data[0].id;
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    openChargeModal() {
      this.addChargeOn = true;
    },
    closeChargeModal() {
      this.addChargeOn = false;
    },
    validateStatus() {
      this.validateStatusCall = true;
    },
    async refresh() {
      await this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: this.$route.params.idClient,
      });
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },
    async getStatusWelOnChange() {
      await this.$store.dispatch("DebtSolutionClients/A_SET_STATUS_WEL", 1);
      this.status_wel = this.client.s_welcome;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.$route.params.idClient,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              if (this.isAgent || this.isTechnicalAssistant) {
                const routeData = this.$router.resolve({
                  name: "sales-made-customer-service-add-change",
                });
                window.open(routeData.href, "_blank");
              } else {
                this.$router
                  .push({ name: "sales-made-customer-service-lead" })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async modalAddService() {
      const data = {
        lead_id: this.client.lead_id,
        lead_name: this.client.client_name,
        accounts: '[{"program": "Credit Experts"}]',
      };
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        this.modalGeneralService(data, 3);
        await this.createEvent(data);
      }
    },
    async modalChangeService() {
      const data = {
        lead_id: this.client.lead_id,
        lead_name: this.client.client_name,
        accounts: '[{"program": "Credit Experts"}]',
      };
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    ...mapActions({
      A_GET_SELLERS: "CrmGlobalStore/A_GET_SELLERS",
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      getHistoryLetter: "ParagonClientDashboard/getHistoryLetter",
      A_CLAIMS: "DebtSolutionClients/A_CLAIMS",
      SET_PROGRAM: "crm-store/SET_PROGRAM",
      SET_CATCHER: "crm-store/SET_CATCHER",
      SET_SELLER: "crm-store/SET_SELLER",
      A_SET_TRACKING_ID: "DashboardCustomerServiceStore/A_SET_TRACKING_ID",
      endCallround: "DbAgentStore/A_END_CR",
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
      // A_COUNT_ANALYSIS_CR: 'CeAnalysisCrStore/A_COUNT_ANALYSIS_CR'
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
      A_GET_NEW_ITEMS_COUNTER: "NewItemsStore/A_GET_NEW_ITEMS_COUNTER",
      updateEfectivity: "ReportStore/A_REFRESH_EFECTIVITY",
    }),
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.$store.dispatch("AnalysisCrStore/A_COUNT_ANALYSIS_CR", params);
    },
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    async getEfectivityClient() {
      const { data, status } =
        await ConnectionClientDashboardService.getEfectivity({
          account_id: this.$route.params.idClient,
        });
      if (status == 200 && Boolean(data.data)) {
        this.efectivityClient.positive = data.data.accounts_positive;
        this.efectivityClient.deleted = data.data.accounts_deleted;
        this.efectivityClient.updated = data.data.accounts_updated;
        this.efectivityClient.total_accounts = data.data.total_accounts;
      } else {
        this.efectivityClient.positive = 0;
        this.efectivityClient.deleted = 0;
        this.efectivityClient.updated = 0;
        this.efectivityClient.total_accounts = 0;
      }
    },
    efectivityPercentage(efectivityType, total_accounts) {
      return `${parseInt((efectivityType * 100) / total_accounts)}%`;
    },
    async getSellers() {
      try {
        await this.A_GET_SELLERS({
          modul: this.moduleId,
          body: { roles: "[]", type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getSellers:", error);
      }
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS({});
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async openModalHistoryLetter() {
      await this.getHistoryLetter(this.$route.params.idClient);
      this.activeModalHistoryLetter = true;
    },
    closeModalHistoryLetter() {
      this.activeModalHistoryLetter = false;
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async leadInfo() {
      this.addPreloader();
      await Promise.all([
        this.getLeadEdit(),
        this.getPrograms(),
        this.getStateLeads(),
        this.getStatusLeads(),
        this.getSourceLeads(),
        this.getSourceNames(),
        this.getStates(),
        this.getEeuuStates(),
        this.getCountries(),
        this.getOwners(),
      ]);
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async countRequestNcrForUpdate() {
      const { data } = await ClientsOptionsServices.countRequestNcrForUpdate({
        client_account_id: this.$route.params.idClient,
      });
      this.countNcrForUpdate = data.data;
    },
    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },
    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },
    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
      this.countRequestNcrForUpdate();
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = true;
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },
    closeModalCallRound() {
      this.modalCallRound = false;
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalStatement() {
      this.modalStatementOpen = true;
    },
    closeModalStatement() {
      this.modalStatementOpen = false;
    },
    openModalStatusReport() {
      this.modalStatusReport = true;
    },
    closeModalStatus() {
      this.modalStatusReport = false;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
      this.getCounterClaims();
    },
    async changeToSessionActive() {
      try {
        const params = {
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId,
          status_sub_module_id: 1,
        };
        await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
      } catch (e) {
        console.error(e);
      }
    },
    changeStatusModalCreateNcrRealtor(visible) {
      this.modalCreateNcrRealtor = visible;
    },
    async getUserAccount() {
      if (this.hasAccess || this.isSpecialRole) {
        const data = await ClientsDashboardServices.createTrackingDashboard({
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId,
          motive_id: this.isSpecialRole ? 6 : this.typeAccess,
          action_id: this.isSpecialRole ? 4 : this.wayAccess,
          account: this.client.account,
        });
        if (data.status === 200) {
          this.A_SET_TRACKING_ID(data.data[0].id);
        }
      }
    },
    async getCounterClaims() {
      this.totalClaims = await ClaimV2Service.countClientClaims({
        client_account_id: this.client.id,
      });
    },
  },
  watch: {
    "client.account": async function (oldv, newv) {
      if (oldv) {
        await this.getUserAccount();
      }
    },
    async getEfectivity(state) {
      if (state) {
        await this.getEfectivityClient();
        this.updateEfectivity(false);
      }
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.custom-button {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.gap-50 {
  gap: 5px;
}
.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}
.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

#circle {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}

#triangle {
  /* Class created to represent clients recovered from connection hold*/
  width: 0;
  height: 0;
  background-color: transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #ffc107;
  margin: 0.2rem;
  margin-top: 0.05rem;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}
.send-cr >>> .dropdown-toggle {
  padding: 0.6rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 3px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 20px;
}
.custom-badge-number {
  margin: 0 5px;
}
</style>
